
































































import Vue from 'vue';
import * as api from '@/api/project';
import moment from 'moment';
interface resData {
  [key: string]: any;
}
export default Vue.extend({
  name: 'projectList',
  data() {
    return {
      listData: [],
      spinning: false,
      busy: false,
      lvisible: {
        type: Boolean,
        default: false,
      },
      page: {
        currentPage: 1,
        pageSize: 3000,
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    heandDate(val: Date) {
      return moment(val).format('YYYY-MM-DD');
    },
    openPro(item) {
      const { constructId, sequenceNbr, auditStatus, projectSpaceStatus } =
        item;
      if (auditStatus === 0 && projectSpaceStatus !== 1) return;
      this.$router.push({
        path: '/ysf/project/detail',
        query: { sequenceNbr: constructId, spaceId: sequenceNbr },
      });
      location.reload();
    },
    fetchData() {
      this.spinning = true;
      api
        .coccMtdsResource_1_projectSpace_getProjectSpaceList()
        .then((res: resData) => {
          let { result } = res;
          result.forEach((item) => {
            item.imageType = item.coverUrl?.substring(
              item.coverUrl.lastIndexOf('/') + 1,
              item.coverUrl.length
            );
          });
          this.listData = result;
          this.spinning = false;
        });
    },
  },
});
